import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import "./styles/blueCircle.scss";

function ExecutiveLeadership({ type }) {
  const data = useStaticQuery(graphql`
    query ExecutiveLeadership {
      board: allMdx(
        filter: { frontmatter: { tags: { eq: "board" } } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          frontmatter {
            title
            headshot
            author
            credentials
          }
          body
        }
      }
      acad_admin: allMdx(
        filter: { frontmatter: { tags: { eq: "acad_admin" } } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          frontmatter {
            title
            headshot
            author
            credentials
          }
          body
        }
      }
    }
  `);

  if (type === "board") {
    return (
      <Row className="executive-leadership">
        {data.board.nodes.map((node) => (
          <Col xs={12} lg={6} className="quote mb-lg-5 mb-4 px-4">
            <Row className="mb-n4 ps-4">
              <Col xs={12} className="mb-3 mb-md-0 px-0">
                {node.frontmatter.headshot && (
                  <img
                    src={node.frontmatter.headshot}
                    alt={`Headshot of ${node.frontmatter.title}`}
                    className="img-fluid img-rounded stacked-img"
                    width="120"
                    height="120"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="p-4 white-bg">
                <h6 className="el-creds">{node.frontmatter.credentials}</h6>
                <h5 className="el-name mb-4">{node.frontmatter.author}</h5>
                <MDXRenderer>{node.body}</MDXRenderer>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  } else {
    return (
      <Row className="executive-leadership">
        {data.acad_admin.nodes.map((node) => (
          <Col xs={12} lg={6} className="quote mb-4 px-4">
            <Row className="mb-n4 ps-4">
              <Col xs={12} className="mb-3 mb-md-0 px-0">
                {node.frontmatter.headshot && (
                  <img
                    src={node.frontmatter.headshot}
                    alt={`Headshot of ${node.frontmatter.title}`}
                    className="img-fluid img-rounded stacked-img"
                    width="120"
                    height="120"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="p-4 white-bg">
                <h6 className="el-creds">{node.frontmatter.credentials}</h6>
                <h5 className="el-name mb-4">{node.frontmatter.author}</h5>
                <MDXRenderer>{node.body}</MDXRenderer>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  }
}

export default ExecutiveLeadership;
